@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");
.landing-component {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    .carousel-component {
        width: 100%;
        .carousel-content {
            margin-top: 80px;
            height: calc(100vh - 80px);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
                margin: 10% 30px;
                color: #1c7eb7;
                font-size: 3em;
                padding: 20px;
                font-weight: 700;
                font-family: "Courgette", cursive;
                @media (max-width: 768px) {
                    font-size: 2em;
                    width: 50%;
                }
            }
        }

        .five {
            background: url("../images/landing7.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

.btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 8px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    transition: all 0.2s;
    position: absolute;
    font-size: 18px;
    color: #02ad5a;
    font-family: "Muli";
}
.text-box{
    margin: 10px 0 0 -4px;
}
.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
    background-color: #fff;
    color: #777;
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
}

.btn-white::after {
    background-color: #fff;
}

.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.btn-animated {
    animation: moveInBottom 5s ease-out;
    animation-fill-mode: backwards;
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
