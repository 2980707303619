.header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin: 10px auto;
    color: #1c7eb7;
    font-weight: 700;
    font-family: "Courgette";
    // border-bottom: 1px solid #1c8cc7;
    width: 200px;
}
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10%;
    font-family: "Muli";
    letter-spacing: 1px;
    line-height: 1.75;
    font-size: 16px;
    text-align: center;
}
.content-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 20px;
    img {
        width: 14.66%;
        margin: 1%;
        cursor: pointer;
        box-shadow: 0px 2px 8px #1c7eb747;
        @media (max-width: 768px) {
            width: 31.33%;
        }
        @media (max-width: 414px) {
            width: 48%;
        }
        // border: 1px solid red;
        &:hover {
            box-shadow: 0px 2px 16px #1c7fb78f;
        }
    }
}
#contact {
    .contact-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 12px;
        .wrap {
            padding: 20px;
            font-size: 16px;
            font-family: "Muli";
            box-shadow: 1px 2px 20px 3px #1c7eb75c;
            margin-bottom: 16px;
        }
    }
    .label {
        padding-left: 4px;
        font-weight: 600;
    }
    .item {
        display: flex;
        justify-content: flex-start;
        padding: 4px 10px;
        .icon {
            margin-right: 6px;
        }
        .value {
            margin-left: 6px;
        }
    }
    .social {
        // display: flex;
        // justify-content: center;
        .icons{
            padding-left: 28px;
        }
        .anticon {
            font-size: 24px;
        }
    }
}
