.header-component {
    width: 100%;
    height: 80px;
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    box-shadow: -1px -10px 20px 0px #1c80bd;
    .logo {
        margin: 0 16px;
        font-family: "Courgette";
        font-size: 22px;
        color: #1c7eb7;
        font-weight: 500;
        height: 100%;
        padding: 10px;
        img {
            height: 100%;
        }
    }
    .menu-items {
        display: flex;
        justify-content: space-around;
        margin: 0 16px;
        @media (max-width: 560px) {
            display: none;
        }
        .menu-item {
            padding: 4px 8px;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 14px;
        }
        .active {
            color: #1c7eb7;
            font-weight: bold;
        }
        a {
            color: black;
        }
    }
}
#about,
#product {
    padding-top: 80px;
}
